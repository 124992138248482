/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://xysbpgqds5hftfeci5ylhdaqpy.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-central-1:b83072b1-a753-4a9e-82b5-733eb49cf500",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_CkMcJBK4x",
    "aws_user_pools_web_client_id": "7a8m38uk717g0tjod6g179cg4f",
    "oauth": {
        "domain": "eat2gether.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "https://admin.2gether.rocks/,https://sit.2gether.rocks/,https://sit2gether.materna.de/",
        "redirectSignOut": "https://2gether.rocks/,https://sit2gether.materna.de/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "GIVEN_NAME",
        "FAMILY_NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "sit2getherroomplanstoragee55a8-prodtwo",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
